import React, { useReducer } from "react";
import HomeContext from "./homeContext";
import HomeReducer from "./homeReducer";
import axios from "axios";
import clienteAxios from "../../config/axios";

import {
  HOME_EXITOSO,
  HOME_LOADIN,
  HOME_ERROR,
  HOME_CLEANER,
  HOME_CLEANER_PARCIAL,
  HOME_GET_GRAPHYC,
  HOME_LOADING_GRAPHYC,
  HOME_ERROR_GRAPHYC,
  HOME_RESETEAR_GRAPHYC,
} from "../../types";

import {
  SUMMARY_FUND_CLEANER,
  SUMMARY_FUND_ERROR,
  SUMMARY_FUND_EXITOSO,
  SUMMARY_FUND_LOADING,
  OPEN_MODAL_SIX_APORTS,
} from "../../types/home";

const HomeState = (props) => {
  const initialState = {
    fondos: [],
    total: null,
    mensajeAlerta: null,
    loading: false,
    opcionesMenu: null,
    opcionesTramites: null,
    summaryFundData: null,
    summaryFundLoading: false,
    graphycs: [],
    loadingGraphyc: false,
    mensajeAlertaGraphyc: null,
    openModalAport: false,
  };
  const urlBase =
    "https://www.afphabitat.com.pe/privado/admin/investments/home/api";
  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response) {
        if (
          error.response.status === 500 ||
          error.response.status === 400 ||
          error.response.status === 404
        ) {
          dispatch({
            type: origin_error,
            payload: {
              code: 1, // su token a expirado
              msg: "Error en los servicios, por favor recargue la página.",
            },
          });
        } else if (
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.message === "Unauthenticated."
        ) {
          dispatch({
            type: origin_error,
            payload: {
              code: 2, // su token a expirado
              msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
            },
          });
        } else {
          dispatch({
            type: origin_error,
            payload: {
              code: 1, // su token a expirado
              msg: error.response.data.message,
            },
          });
        }
      }
    }
  };

  const obtenerDatosGraphyc = async () => {
    dispatch({
      type: HOME_LOADING_GRAPHYC,
      payload: true,
    });

    try {
      const respuesta = await axios.get(urlBase);
      dispatch({
        type: HOME_GET_GRAPHYC,
        payload: respuesta.data,
      });
    } catch (error) {
      if (error.message === "Network Error") {
        dispatch({
          type: HOME_ERROR_GRAPHYC,
          payload: {
            code: 1, // error de red
            msg: "Su red presenta lentitud, por favor recargue la página.",
          },
        });
      } else {
        if (error.response?.status === 500) {
          dispatch({
            type: HOME_ERROR_GRAPHYC,
            payload: {
              code: 1, // su token a expirado
              msg: "Error en los servicios, por favor recargue la página.",
            },
          });
        } else if (
          error.response?.data?.errors?.message === "Unauthenticated."
        ) {
          dispatch({
            type: HOME_ERROR_GRAPHYC,
            payload: {
              code: 2, // su token a expirado
              msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
            },
          });
        }
      }
    }
  };

  const obtenerDatosHome = async () => {
    dispatch({
      type: HOME_LOADIN,
      payload: true,
    });
    obtenerOpcionesMenu();
    try {
      const respuesta = await clienteAxios.get("/home");
      dispatch({
        type: HOME_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      if (error.message === "Network Error") {
        dispatch({
          type: HOME_ERROR,
          payload: {
            code: 1, // error de red
            msg: "Su red presenta lentitud, por favor recargue la página.",
          },
        });
      } else {
        if (error.response?.status === 500) {
          dispatch({
            type: HOME_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Error en los servicios, por favor recargue la página.",
            },
          });
        } else if (
          error.response?.data?.errors?.message === "Unauthenticated."
        ) {
          dispatch({
            type: HOME_ERROR,
            payload: {
              code: 2, // su token a expirado
              msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
            },
          });
        }
      }
    }
  };

  const obtenerOpcionesMenu = async () => {
    try {
      const respuesta = await clienteAxios.post("/menu");
      sessionStorage.setItem(
        "opcionesTramite",
        JSON.stringify(respuesta.data.r_validacion_tramites)
      );

      dispatch({
        type: "OPCIONESMENU",
        payload: respuesta.data.r_menu[0],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const consultarOsiptel = async (phone) => {
    try {
      const response = await clienteAxios.get(`/consulta-osiptel/${phone}`);
      return response.data;
    } catch (error) {
      if (error.response?.status === 500) {
        dispatch({
          type: HOME_ERROR,
          payload: {
            code: 1,
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (
        error.response?.data?.errors?.message === "Unauthenticated."
      ) {
        dispatch({
          type: HOME_ERROR,
          payload: {
            code: 2,
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      }
    }
  };

  const limpiarVariablesHome = () => {
    dispatch({
      type: HOME_CLEANER,
    });
  };

  const limpiarVariablesHomeParcial = () => {
    dispatch({
      type: HOME_CLEANER_PARCIAL,
    });
  };

  const obtenerSummaryFund = async (data) => {
    dispatch({
      type: SUMMARY_FUND_LOADING,
      payload: true,
    });
    try {
      const obj = {
        producto: data,
      };
      const respuesta = await clienteAxios.post("/summary-fund", obj);
      dispatch({
        type: SUMMARY_FUND_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, SUMMARY_FUND_ERROR);
    }
  };

  const limpiarVariablesSummaryFund = () => {
    dispatch({
      type: SUMMARY_FUND_CLEANER,
    });
  };
  const limpiarVariablesgRAPHYC = () => {
    dispatch({
      type: HOME_RESETEAR_GRAPHYC,
    });
  };

  const modalSixAport = (dato) => {
    dispatch({
      type: OPEN_MODAL_SIX_APORTS,
      payload: dato,
    });
  };
  const [state, dispatch] = useReducer(HomeReducer, initialState);
  return (
    <HomeContext.Provider
      value={{
        fondos: state.fondos,
        total: state.total,
        mensajeAlerta: state.mensajeAlerta,
        loading: state.loading,
        opcionesMenu: state.opcionesMenu,
        summaryFundData: state.summaryFundData,
        summaryFundLoading: state.summaryFundLoading,
        graphycs: state.graphycs,
        mensajeAlertaGraphyc: state.mensajeAlertaGraphyc,
        loadingGraphyc: state.loadingGraphyc,

        openModalAport: state.openModalAport,
        obtenerDatosHome,
        obtenerDatosGraphyc,
        obtenerOpcionesMenu,
        consultarOsiptel,
        limpiarVariablesHome,
        limpiarVariablesHomeParcial,
        obtenerSummaryFund,
        limpiarVariablesSummaryFund,
        limpiarVariablesgRAPHYC,

        modalSixAport,
      }}
    >
      {props.children}
    </HomeContext.Provider>
  );
};

export default HomeState;
