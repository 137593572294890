import React, { useState, useContext, useEffect } from "react";
import MisdatosContext from "../../context/misdatos/misdatosContext";
import AuthContext from "../../context/autenticacion/authContext";
import ClaveWebSuccess from "../ClaveWebSuccess";

// Componentes de MUI
import {
    // MODAL
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    // UI
    Typography,
    IconButton,
    TextField,
    Box,
    Divider,
    LinearProgress,
    // isMobile 
    useMediaQuery,
    useTheme,
    Drawer
} from "@mui/material";
import {
    // PAGE
    Card,
    CardBody
} from "reactstrap";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Cancel from "@mui/icons-material/Cancel";
import { Visibility, VisibilityOff, Close } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import ModalToken from "../ModalToken";
import ModalTokenError from "../ModalTokenError";
import { CardContainer, Button } from "..";

const globalErrors = {
    r_clave_nueva: "",
    r_clave_confirmar: "",
};

const FormularioCambioClaveContent = ({ description, fixedButton, isModal, descriptionCenter, isDatosPersonales, isGeneraClaveWeb }) => {
    const misdatosContext = useContext(MisdatosContext);
    const { cambiarClavePost, loadingBtnClavePost, resultadoClavePost } = misdatosContext;

    const authContext = useContext(AuthContext);
    const { cambioClavePrimeraVezExitoso, cambio_clave, closeModalCambioClave } = authContext;

    const [claves, setClaves] = useState({
        r_clave_nueva: "",
        r_clave_confirmar: "",
    });

    const [errores, setErrores] = useState(globalErrors);

    const [openModalToken, setOpenModalToken] = useState(false);
    const [mostrarModalToken, setMostrarModalToken] = useState(null);

    const [showPassword, setShowPassword] = useState({
        r_clave_nueva: false,
        r_clave_confirmar: false,
    });

    // Validaciones de la nueva clave
    const [requirements, setRequirements] = useState({
        length: false,
        alphanumeric: false,
        upperLowerCase: false,
        hasNotCaracteres: false,
    });

    // Calcula cuántos requisitos están cumplidos
    const validCount = Object.values(requirements).filter(Boolean).length;

    // Define mensajes y color según el número de requisitos cumplidos
    let progressMessage = "";
    let progressColor = "error";

    if (validCount === 0) {
        progressMessage = "Tu clave web aún no cumple los requisitos mínimos.";
        progressColor = "error";
    } else if (validCount > 0 && validCount < 4) {
        progressMessage = "Vas por buen camino, pero aún faltan algunos requisitos.";
        progressColor = "error";
    } else if (validCount === 4) {
        progressMessage = "¡Todo listo! Tu clave web cumple con los requisitos.";
        progressColor = "success";
    }

    // -----------------------------
    //   FUNCIONES DE VALIDACIÓN
    // -----------------------------
    const validatePassword = (value) => {
        const isEmpty = value.trim() === "";

        const newRequirements = {
            length: !isEmpty && value.length === 8,
            alphanumeric: !isEmpty && /[A-Za-z]/.test(value) && /[0-9]/.test(value),
            upperLowerCase: !isEmpty && /[A-Z]/.test(value) && /[a-z]/.test(value),
            hasNotCaracteres: !isEmpty && !/[^A-Za-z0-9]/.test(value),
        };
        setRequirements(newRequirements);
    };

    const handleNewPasswordChange = (e) => {
        const { name, value } = e.target;
        setClaves({ ...claves, [name]: value });
        validatePassword(value);
        setErrores({ ...errores, [name]: "" });
    };

    const handlerPassword = (e) => {
        const { name, value, validity } = e.target;
        const valor = validity.valid ? value : "";
        setClaves({ ...claves, [name]: valor });
        setErrores({ ...errores, [name]: "" });
    };

    const handlerDisable = (e) => {
        e.preventDefault();
    };

    const validarData = () => {
        let mensajesError = {
            r_clave_nueva: "",
            r_clave_confirmar: "",
        };

        // Nueva clave
        if (!claves.r_clave_nueva.trim()) {
            mensajesError.r_clave_nueva = "campo requerido";
        } else {
            if (claves.r_clave_nueva.length !== 8) {
                mensajesError.r_clave_nueva = "La nueva clave debe tener 8 caracteres";
            } else if (!/^[A-Za-z0-9]+$/.test(claves.r_clave_nueva)) {
                mensajesError.r_clave_nueva = "Solo se permiten letras y números";
            } else if (!(/[A-Za-z]/.test(claves.r_clave_nueva) && /[0-9]/.test(claves.r_clave_nueva))) {
                mensajesError.r_clave_nueva = "Debe incluir al menos una letra y un número";
            } else if (/[^A-Za-z0-9]/.test(claves.r_clave_nueva)) {
                mensajesError.r_clave_nueva = "No se permiten los caracteres especiales";
            }
        }

        // Confirmación de la nueva clave
        if (!claves.r_clave_confirmar.trim()) {
            mensajesError.r_clave_confirmar = "campo requerido";
        } else {
            if (claves.r_clave_confirmar.length !== 8) {
                mensajesError.r_clave_confirmar = "La confirmación debe tener 8 caracteres";
            } else if (claves.r_clave_confirmar !== claves.r_clave_nueva) {
                mensajesError.r_clave_confirmar = "No coincide con la nueva clave";
            }
        }

        setErrores(mensajesError);

        // Verificamos si hay algún error
        for (let key in mensajesError) {
            if (mensajesError[key]) return false;
        }
        return true;
    };

    // -----------------------------
    //   SUBMIT
    // -----------------------------
    const handleSubmit = () => {
        if (validarData()) {
            setOpenModalToken(true);
        }
    };

    const handleUpdate = async (token) => {
        try {
            let bodyFormData = new FormData();
            bodyFormData.set("v_nueva_clave", claves.r_clave_nueva);
            bodyFormData.set("v_token_digital", token);

            const response = await cambiarClavePost(bodyFormData);

            // Verificamos respuesta exitosa esperada
            if (response.status === "200" && response.data.r_respuesta !== -3) {
                setOpenModalToken(false);
            } else {
                setMostrarModalToken({
                    r_mensaje: 'Ocurrió un error inesperado al procesar su solicitud',
                });
            }
        } catch (error) {
            console.error("Ocurrió un error inesperado: ", error);
            setMostrarModalToken({
                r_mensaje: 'Ocurrió un error inesperado al procesar su solicitud',
            });
        }
    };

    const handlerClose = () => {
        closeModalCambioClave();
    };

    // -----------------------------
    //   EFECTOS
    // -----------------------------
    useEffect(() => {
        if (resultadoClavePost && resultadoClavePost.r_respuesta === "1") {
            cambioClavePrimeraVezExitoso();
        }
        // eslint-disable-next-line
    }, [resultadoClavePost]);

    useEffect(() => {
        if (resultadoClavePost) {
            if (resultadoClavePost.r_respuesta === 1) {
                setMostrarModalToken(resultadoClavePost);
                setOpenModalToken(false);
            } else {
                setMostrarModalToken(null);
                setOpenModalToken(false);
            }
        }
    }, [resultadoClavePost]);

    // -----------------------------
    //   MOSTRAR / OCULTAR PASSWORD
    // -----------------------------
    const toggleShowPassword = (fieldName) => {
        setShowPassword((prev) => ({
            ...prev,
            [fieldName]: !prev[fieldName],
        }));
    };

    // Se determina si se cumplen todos los requisitos
    const allValid =
        Object.values(requirements).every(Boolean) &&
        claves.r_clave_confirmar.length === 8 &&
        claves.r_clave_confirmar === claves.r_clave_nueva;

    // -----------------------------
    //   RENDER
    // -----------------------------
    return (
        <Box>
            {!cambio_clave?.loCambio ? (
                <>
                    {description && (
                        <Typography variant="body2" sx={{ mb: 1, textAlign: descriptionCenter ? "left" : "center" }}>
                            {description}
                        </Typography>
                    )}
                    <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
                        Nueva clave web
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Ejemplo: Hbt12084"
                        type={showPassword.r_clave_nueva ? "text" : "password"}
                        name="r_clave_nueva"
                        value={claves.r_clave_nueva}
                        inputProps={{ maxLength: 8 }}
                        onPaste={handlerDisable}
                        onCopy={handlerDisable}
                        onChange={handleNewPasswordChange}
                        error={!!errores.r_clave_nueva}
                        helperText={errores.r_clave_nueva}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onMouseDown={() => toggleShowPassword("r_clave_nueva")}
                                        onMouseUp={() => toggleShowPassword("r_clave_nueva")}
                                        edge="end"
                                    >
                                        {showPassword.r_clave_nueva ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        sx={{
                            mb: 2,
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "16px",
                                background: "#F6F8F9",
                                "& fieldset": {
                                    borderColor: "#ccc"
                                },
                                "&:hover fieldset": {
                                    borderColor: "#2F6D95"
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#2F6D95"
                                }
                            }
                        }}
                    />

                    <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
                        Confirmar clave web
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Confirmar clave"
                        type={showPassword.r_clave_confirmar ? "text" : "password"}
                        name="r_clave_confirmar"
                        value={claves.r_clave_confirmar}
                        inputProps={{ maxLength: 8 }}
                        onPaste={handlerDisable}
                        onCopy={handlerDisable}
                        onChange={handlerPassword}
                        error={
                            !!errores.r_clave_confirmar ||
                            (claves.r_clave_confirmar.length === 8 &&
                                claves.r_clave_confirmar !== claves.r_clave_nueva)
                        }
                        helperText={
                            errores.r_clave_confirmar ||
                            (claves.r_clave_confirmar.length === 8 &&
                                claves.r_clave_confirmar !== claves.r_clave_nueva
                                ? "No coincide con la nueva clave"
                                : "")
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onMouseDown={() => toggleShowPassword("r_clave_confirmar")}
                                        onMouseUp={() => toggleShowPassword("r_clave_confirmar")}
                                        edge="end"
                                    >
                                        {showPassword.r_clave_confirmar ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        sx={{
                            mb: 2,
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "16px",
                                background: "#F6F8F9",
                                "& fieldset": {
                                    borderColor: "#ccc"
                                },
                                "&:hover fieldset": {
                                    borderColor: "#2F6D95"
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#2F6D95"
                                }
                            }
                        }}
                    />
                    {/* Progress Bar*/}
                    <Box sx={{ mt: 1 }}>
                        {claves.r_clave_nueva ? (
                            <>
                                {/* Barra de progreso con color dinámico */}
                                <LinearProgress
                                    variant="determinate"
                                    value={(validCount / 4) * 100}
                                    color={progressColor}
                                    sx={{ height: 8, borderRadius: 4 }}
                                />
                                {/* Mensaje de progreso */}
                                <Typography variant="body2" sx={{ my: 1 }}>
                                    {progressMessage}
                                </Typography>
                            </>
                        ) : (
                            <>
                                {/* Barra ploma sin mensaje */}
                                <LinearProgress
                                    variant="determinate"
                                    value={0}
                                    sx={{
                                        height: 8,
                                        borderRadius: 4,
                                        backgroundColor: '#EDEDED',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#BDBDBD'
                                        }
                                    }}
                                />
                            </>
                        )}
                    </Box>

                    {/** Lista de requisitos */}
                    <ul style={{ paddingLeft: 0, listStyleType: 'none', marginTop: 8 }}>
                        {/* Para "8 caracteres" */}
                        <li
                            style={{
                                color: (claves.r_clave_nueva.length !== 8)
                                    ? '#9E9E9E'
                                    : requirements.length ? '#2E7D32' : '#CE1335'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {(claves.r_clave_nueva.length !== 8)
                                    ? <Cancel sx={{ fontSize: 16, mr: 1, color: '#9E9E9E' }} />
                                    : (requirements.length
                                        ? <CheckCircleIcon sx={{ fontSize: 16, mr: 1, color: '#2E7D32' }} />
                                        : <Cancel sx={{ fontSize: 16, mr: 1, color: '#CE1335' }} />)
                                }
                                Alfanumérica de 8 caracteres
                            </div>
                        </li>

                        {/* Para "Letras y números" */}
                        <li
                            style={{
                                color: (claves.r_clave_nueva.length !== 8)
                                    ? '#9E9E9E'
                                    : requirements.alphanumeric ? '#2E7D32' : '#CE1335'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {(claves.r_clave_nueva.length !== 8)
                                    ? <Cancel sx={{ fontSize: 16, mr: 1, color: '#9E9E9E' }} />
                                    : (requirements.alphanumeric
                                        ? <CheckCircleIcon sx={{ fontSize: 16, mr: 1, color: '#2E7D32' }} />
                                        : <Cancel sx={{ fontSize: 16, mr: 1, color: '#9E9E9E' }} />)
                                }
                                Letras y números
                            </div>
                        </li>

                        {/* Para "Mínimo una mayúscula y una minúscula" */}
                        <li
                            style={{
                                color: (claves.r_clave_nueva.length !== 8)
                                    ? '#9E9E9E'
                                    : requirements.upperLowerCase ? '#2E7D32' : '#9E9E9E'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {(claves.r_clave_nueva.length !== 8)
                                    ? <Cancel sx={{ fontSize: 16, mr: 1, color: '#9E9E9E' }} />
                                    : (requirements.upperLowerCase
                                        ? <CheckCircleIcon sx={{ fontSize: 16, mr: 1, color: '#2E7D32' }} />
                                        : <Cancel sx={{ fontSize: 16, mr: 1, color: '#9E9E9E' }} />)
                                }
                                Mínimo una mayúscula y una minúscula
                            </div>
                        </li>

                        {/* Para "Carcatéres especiales" */}
                        <li
                            style={{
                                color: !claves.r_clave_nueva ? '#9E9E9E'
                                    : requirements.hasNotCaracteres ? '#2E7D32'
                                        : '#9E9E9E'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {!claves.r_clave_nueva ? (
                                    <Cancel sx={{ fontSize: 16, mr: 1, color: '#9E9E9E' }} />
                                ) : requirements.hasNotCaracteres ? (
                                    <CheckCircleIcon sx={{ fontSize: 16, mr: 1, color: '#2E7D32' }} />
                                ) : (
                                    <Cancel sx={{ fontSize: 16, mr: 1, color: '#9E9E9E' }} />
                                )}
                                Sin tildes ni caracteres especiales
                            </div>
                        </li>
                    </ul>
                    {isModal && (<Divider sx={{ my: 2 }} />)}
                    {fixedButton ? (
                        <>
                            {/* Espacio para el botón fixeado */}
                            <Box
                                sx={{
                                    position: "fixed",
                                    bottom: 0,
                                    // Centramos horizontalmente usando left 50% + transform
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    // Márgenes y ancho máximo
                                    width: "100%",
                                    maxWidth: 480,
                                    px: 2,
                                    mb: 2,
                                }}
                            >
                                <Button
                                    fullWidth
                                    sx={{
                                        maxWidth: "100% !important",
                                    }}
                                    onClick={handleSubmit}
                                    disabled={!allValid || loadingBtnClavePost}
                                >
                                    {loadingBtnClavePost ? "Procesando..." : "Actualizar clave web"}
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <Button
                            fullWidth
                            sx={{
                                maxWidth: "100% !important",
                            }}
                            onClick={handleSubmit}
                            disabled={!allValid || loadingBtnClavePost}
                        >
                            {loadingBtnClavePost ? "Procesando..." : "Actualizar clave web"}
                        </Button>
                    )}

                </>
            ) : (
                // En datos personales no mostrará modal de éxito de cambio de clave
                !isDatosPersonales && (
                    <ClaveWebSuccess
                        open={!isDatosPersonales}
                        onClose={closeModalCambioClave}
                        title={
                            !isGeneraClaveWeb
                                ? "Clave web actualizada con éxito"
                                : "Clave web generada con éxito"
                        }
                        description={
                            !isGeneraClaveWeb
                                ? (
                                    <>
                                        ¡Excelente! Tu clave web se actualizó correctamente. Ahora puedes seguir navegando en{" "}
                                        <strong>Mi Hábitat Digital</strong>.
                                    </>
                                )
                                : (
                                    <>
                                        ¡Excelente! <strong>Generaste tu nueva clave web</strong> correctamente. Ahora estás listo para acceder a{" "}
                                        <strong>Mi Hábitat Digital</strong>.
                                    </>
                                )
                        }
                        redirect={isGeneraClaveWeb ? "/" : null}
                    />
                )
            )}
            {/** Modales para token */}
            {openModalToken && (
                <ModalToken
                    showModal={openModalToken}
                    cerrarModal={() => setOpenModalToken(false)}
                    guardarCode={handleUpdate}
                />
            )}
            {mostrarModalToken && (
                <ModalTokenError
                    mensaje={mostrarModalToken.r_mensaje}
                    cerrarModal={() => setMostrarModalToken(false)}
                    guardarCode={() => {
                        setMostrarModalToken(false);
                        setOpenModalToken(true);
                    }}
                />
            )}
        </Box>
    );
};

/**
 * Componente principal que decide si se renderiza como modal o inline,
 * en base a la prop "isModal".
 */
const FormularioCambioClave = ({
    isModal = false,
    isDatosPersonales = false,
    isGeneraClaveWeb = false,
    onClose,
    title = "Actualización de clave web",
    description,
}) => {
    const { closeModalCambioClave } = useContext(AuthContext);

    // Detectar si es móvil
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handlerCloseModal = () => {
        if (onClose) {
            onClose();
        } else {
            closeModalCambioClave();
        }
    };

    const content = (
        <FormularioCambioClaveContent
            description={description}
            fixedButton={!isModal && isMobile}
            isModal={isModal}
            descriptionCenter={isGeneraClaveWeb}
            isDatosPersonales={isDatosPersonales}
            isGeneraClaveWeb={isGeneraClaveWeb}
        />
    );
    const header = (
        <Typography variant="h5" align="center" sx={{ mb: 2 }}>
            {title}
        </Typography>
    );

    const box = (
        <Box
            sx={{
                maxWidth: isDatosPersonales ? 480 : "100%",
                margin: "0 auto",
                px: 2,
                py: 3,
            }}
        >
            {header}
            {content}
        </Box>
    );

    // 1) MODO MODAL
    if (isModal) {
        return isMobile ? (
            // -----------------------------
            // Modal en Mobile => Drawer
            // -----------------------------
            <Drawer
                anchor="bottom"
                open={true}
                onClose={handlerCloseModal}
                PaperProps={{
                    sx: {
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        maxHeight: "95vh",
                        overflowY: "auto",
                    },
                }}
            >
                {box}
            </Drawer>
        ) : (
            // -----------------------------
            // Modal en Desktop => Dialog
            // -----------------------------
            <section className="wraper-modal">
                <section className="py-3">
                    <div id="dialog-container" />
                    <Dialog
                        open={true}
                        onClose={handlerCloseModal}
                        fullWidth
                        maxWidth="xs"
                        container={document.getElementById("dialog-container")}
                    >
                        <DialogTitle
                            sx={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "20px",
                                pb: 1,
                                borderBottom: "none",
                                display: "flex",
                                justifyContent: "center",
                                position: "relative",
                            }}
                        >
                            {title}
                        </DialogTitle>
                        <DialogContent>{content}</DialogContent>
                    </Dialog>
                </section>
            </section>
        );
    }

    // 2) MODO INLINE + MOBILE => diseño 100% ancho, alineado arriba
    if (!isModal && isMobile) {
        return (
            <Box
                fullWidth
                sx={{
                    // minHeight: "100vh",
                    // minHeight: "100% !important",
                    mt: "0 !important",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {/* Contenedor para el título y el formulario */}
                <Box sx={{ px: 2, pt: 3, pb: 10 /* Dejamos hueco para el botón fijo */ }}>
                    <Typography variant="h5" align="left" sx={{ mb: 2 }}>
                        {title}
                    </Typography>

                    {/* Aquí va el formulario con su contenido */}
                    {content}
                </Box>
            </Box>
        );
    }

    // 3) MODO INLINE: Desktop o "isDatosPersonales"
    if (isDatosPersonales) {
        return (
            <CardContainer>
                <Card>
                    <CardBody>{box}</CardBody>
                </Card>
            </CardContainer>
        );
    }

    // 4) MODO INLINE Desktop sin tarjeta (Generación de clave)
    return <div>{box}</div>;
};

export default FormularioCambioClave;
