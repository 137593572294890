import React, { useContext, useEffect, useState } from "react";
import { Header } from "../NewHome/components";
import FooterG from "./components/Footer/FooterG";
import Step01 from "./Step01";
import Step02 from "./Step02";
import GenerarClaveContext from "../../context/generarClave/generarClaveContext";
import ErrorGenerico from "./errors/generico";
import "./index.css";
import { useNotification } from "../../context/notificationbar/notificationBarContext";
import FormularioCambioClave from "../../components/FormularioCambioClave";

const GeneraClaveView = (props) => {
    const { barHeight } = useNotification();
    const generarClaveContexto = useContext(GenerarClaveContext);
    const { gBloqueoPasos, bloquearPasos, gUsuarioExitoso } = generarClaveContexto;
    const [step, setStep] = useState(1)
    const [block, setBlock] = useState(false)

    const changeStep = () => [
        setStep(2)
    ]

    const changeStepOne = () => [
        setStep(1)
    ]

    const bloquear = () => {
        setStep(1)
        setBlock(true)
    }

    return (

        <div className="vertical-hidden">
            <Header barHeight={barHeight} />
            <div className="wrap-genera">
                {(step === 1) ?
                    <div className="privatezone-clave margin-center desktop-loginG padding-32">
                        <ErrorGenerico> </ErrorGenerico>
                        <Step01 changeStepOne={changeStepOne} bloqueado={block} changeStep={changeStep}></Step01>
                    </div>
                    :
                    <div className="privatezone-clave margin-center padding-32" style={{ backgroundColor: "#ffffff" }}>
                        <ErrorGenerico> </ErrorGenerico>
                        <FormularioCambioClave
                            isModal={false}
                            title="Genera tu clave web"
                            isGeneraClaveWeb={true}
                            description={
                                <>
                                    Hola <b>{gUsuarioExitoso ? gUsuarioExitoso.name : ""}</b>, aquí podrás
                                    generar una nueva clave web para ingresar a
                                    <b> Mi Habitat Digital.</b>
                                </>
                            }
                        />
                    </div>
                }
            </div>
        </div>

    );
};

export default GeneraClaveView;
