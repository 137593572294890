import React from "react";
import { default as MaterialButton } from "@mui/material/Button";
import styled from "styled-components";

const ButtonComponent = styled(MaterialButton)`
  &.MuiButtonBase-root.MuiButton-root {
    ${(props) => props.theme.tipography.body1};
    border-width: 1px;
    border-style: solid;
    width: ${(props) => (props.$fullwidth ? "100%" : "auto")};
    max-width: 343px;
    margin: auto;
    text-transform: inherit;
    box-shadow: none;
    border-color: ${(props) =>
    props.$nameBtn === "secondary"
      ? "#ce1336"
      : props.$nameBtn === "tertiary"
        ? "rgba(255, 255, 255, 0.15)"
        : "#fff"};
    background-color: ${(props) =>
    props.$nameBtn === "secondary"
      ? "#fff"
      : props.$nameBtn === "tertiary"
        ? "rgba(255, 255, 255, 0.15)"
        : "#ce1336"};
    color: ${(props) => (props.$nameBtn === "secondary" ? "#ce1336" : "#fff")};
    border-radius: ${(props) => props.theme.spacing(2)};
    padding: ${(props) => props.theme.spacing(1, 4)};
    @media (max-width: 768px) {
      padding: ${(props) => props.theme.spacing(1, 2)};
    }
  }
  &.MuiButtonBase-root.MuiButton-root.Mui-disabled {
    background-color: ${(props) => props.theme.palette.blocked.disabled};
    color: ${(props) => props.theme.palette.grayscale.blocked};
    border: none;
  }

  & i,
  svg {
    margin-right: ${(props) => props.theme.spacing(1)};
    margin-left: ${(props) => props.theme.spacing(1)};
  }
`;
const Index = (props) => {
  const {
    loading,
    disabled,
    children,
    loaderColor,
    name,
    bgColor,
    variant,
    icon,
    fullWidth = false,
    ...rest
  } = props;

  return (
    <ButtonComponent
      $nameBtn={name}
      variant={variant}
      disabled={disabled}
      $bgColor={bgColor}
      $fullwidth={fullWidth}
      {...rest}
    >
      {loading && (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          style={{ marginRight: "7px" }}
        ></span>
      )}
      {icon}
      {children}
    </ButtonComponent>
  );
};

export default Index;
// Button.defaultProps = {
//   name: "primary",
//   bgColor: "",
//   disabled: false,
// };
