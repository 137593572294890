import React, { useContext, useEffect } from "react";
import ReactGa from "react-ga";
import SolicitudProcesada from "./SolicitudProcesada";
import SolicitudNoProcesada from "./SolicitudNoProcesada";

// Nuevo modal clave WEB
import FormularioCambioClave from "../../../components/FormularioCambioClave";

import MisdatosContext from "../../../context/misdatos/misdatosContext";
import AuthContext from "../../../context/autenticacion/authContext";
import { Page } from "../../../components";
import Grid from "@mui/material/Grid";

const MiClaveWeb = () => {
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;
  const misdatosContext = useContext(MisdatosContext);
  const {
    resultadoClavePost,
    limpiarVariablesClaveWebParcial,
    limpiarVariablesClaveWeb,
    mensajeAlertaClavePost,
  } = misdatosContext;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (mensajeAlertaClavePost) {
    } else {
      ReactGa.pageview(window.location.href);
    }
    return () => {
      limpiarVariablesClaveWebParcial();
      actualizarTimer();
    };
    // eslint-disable-next-line
  }, [mensajeAlertaClavePost]);

  return (
    <Page title="Actualización de clave web" showHeader={"mobile"} forceShowHeader={false} paddingMobile="0">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          {resultadoClavePost === null ||
            (resultadoClavePost && resultadoClavePost.r_respuesta === -2) ? (
            // Se utiliza el nuevo formulario en lugar de PrimerPaso
            <FormularioCambioClave
              isModal={false}
              isDatosPersonales={true}
              title="Actualización de clave web"
            />
          ) : resultadoClavePost.r_respuesta === "1" ? (
            <SolicitudProcesada email={resultadoClavePost.r_email} />
          ) : (
            <SolicitudNoProcesada
              mensaje={resultadoClavePost.r_mensaje}
              resetear={limpiarVariablesClaveWeb}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default MiClaveWeb;
