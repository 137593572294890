import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Link } from "react-router-dom";
import ICON_INFO from "../../../assets/icons/info_circle.svg"
import { MessageSnack } from "../../../components";
import { Button } from "../../../components";

const SolicitudProcesada = ({ email }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      // Contenedor principal que simula la "card"
      sx={{
        backgroundColor: "#fff",
        borderRadius: 2,
        // boxShadow: 3,
        p: isMobile ? "16px" : 4,
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Box textAlign="left">
        <Box display="flex" alignItems="center" justifyContent="left" gap={1}>
          <TaskAltIcon sx={{ fontSize: 40, color: "#000000" }} />
          <Typography variant="h6" fontWeight="bold">
            Clave web actualizada con éxito
          </Typography>
        </Box>
        <Typography mt={2}>
          Continúa navegando en{" "}
          <Typography component="span" fontWeight="bold">
            Mi Hábitat Digital.
          </Typography>
        </Typography>

        <Box mt={3}>
          <MessageSnack icon={ICON_INFO} alt="ICON_INFO" marginTop="1.2em">
            <ul className="note-list">
              Te notificaremos del cambio al correo <b>{email}</b>
            </ul>
          </MessageSnack>
        </Box>
      </Box>

      {/* Botón para desktop */}
      {!isMobile && (
        <Box mt={4} textAlign="center">
          <Link to="/mi-habitat-digital/afiliado/inicio">
            <Button fullWidth>Ir al inicio</Button>
          </Link>
        </Box>
      )}

      {/* Botón fijo en mobile */}
      {isMobile && (
        <Box position="fixed" bottom={16} left={0} right={0} px={2}>
          <Link to="/mi-habitat-digital/afiliado/inicio">
            <Button fullWidth>Ir al inicio</Button>
          </Link>
        </Box>
      )}
    </Box>
  );
};


export default SolicitudProcesada;
