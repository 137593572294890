import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  Divider
} from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt'; import CloseIcon from "@mui/icons-material/Close";
import { Button } from "..";

const ClaveWebSuccess = ({ open, onClose, title, description, redirect }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const handleClick = () => {
    if (redirect) {
      history.push(redirect);
    } else {
      onClose();
    }
  };

  const buttonText = redirect ? "Ir a Mi Hábitat Digital" : "Aceptar";

  const content = (
    <Box textAlign="center">
      <Box display="flex" justifyContent="center" alignItems="center" gap={1} mb={1}>
        <TaskAltIcon sx={{ fontSize: 32, color: "#2E7D32" }} />
        <Typography fontWeight="bold" sx={{ fontSize: 20 }}>
          {title}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ mb: 3 }}>
        {description}
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Button onClick={handleClick} fullWidth>
        {buttonText}
      </Button>
    </Box>
  );

  return isMobile ? (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          maxHeight: "auto",
          pb: 2,
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end" p={1}>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
      {content}
    </Drawer>
  ) : (
    <section className="wraper-modal">
      <section className="py-3">
        <div id="dialog-container-success" />
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="xs"
          fullWidth
          container={document.getElementById("dialog-container-success")}
        >
          <Box display="flex" justifyContent="flex-end" p={1}>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent sx={{ pt: 0 }}>{content}</DialogContent>
        </Dialog>
      </section>
    </section>
  );
};

export default ClaveWebSuccess;
