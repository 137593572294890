import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Drawer,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Divider
} from "@mui/material";
import { Button } from "..";

const StepConfirmacion = ({
  titulo,
  descripcion,
  datoMostrado,
  onYes,
  onNo
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const content = (
    <>
      <DialogTitle align="center" >{titulo}</DialogTitle>
      <DialogContent align="center">
        <Typography
          sx={{ fontSize: "14px" }}
          dangerouslySetInnerHTML={{ __html: descripcion }}
        />
        <Box
          sx={{
            mt: 2,
            border: '1px solid #C4C4C4',
            borderRadius: '16px',
            padding: '12px 16px',
            textAlign: 'center',
            fontSize: '16px',
            color: '#000',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {datoMostrado}
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
          fullWidth
          sx={{
            display: "flex",
            gap: 2,
            margin: "0 auto",
          }}
        >
          <Button name="secondary" fullWidth onClick={onNo}>
            No
          </Button>
          <Button fullWidth onClick={onYes}>
            Sí
          </Button>
        </Box>
      </DialogContent>
    </>
  );

  if (isMobile) {
    return (
      <Drawer
        align="center"
        anchor="bottom"
        open={true}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onNo();
          }
        }}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            maxHeight: "90vh",
            overflow: "auto",
          },
        }}
      >
        {content}
      </Drawer>
    );
  }

  return (
    <section className="wraper-modal">
      <section className="py-3">
        <div id="dialog-container-step" />
        <Dialog
          align="center"
          open={true}
          fullWidth
          maxWidth="xs"
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              onNo();
            }
          }}
          container={document.getElementById("dialog-container-step")}
        >
          {content}
        </Dialog>
      </section>
    </section>
  );
};

export default StepConfirmacion;
