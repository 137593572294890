import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Drawer,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  IconButton
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { Button } from "..";

const RecomendacionCambioClave = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [open, setOpen] = useState(true);

  const handlerActualizar = () => {
    onClose();
    history.push("/mi-habitat-digital/afiliado/informacion/clave-web");
  };

  const onClose = () => {
    setOpen(false);
  }

  const content = (
    <>
      <DialogTitle align="center" >
        Actualiza tu clave web
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent align="center">
        <Typography
          sx={{ fontSize: "14px" }}
        />
        Mantén tu cuenta protegida actualizando tu clave web.
        <Divider sx={{ my: 2 }} />
        <Box
          fullWidth
          sx={{
            display: "flex",
            gap: 2,
            margin: "0 auto",
          }}
        >
          <Button name="secondary" fullWidth onClick={onClose}>
            Más tarde
          </Button>
          <Button fullWidth onClick={handlerActualizar}>
            Actualizar
          </Button>
        </Box>
      </DialogContent>
    </>
  );

  if (!open) return null;

  if (isMobile) {
    return (
      <Drawer
        align="center"
        anchor="bottom"
        open={true}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") { }
        }}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            maxHeight: "90vh",
            overflow: "auto",
          },
        }}
      >
        {content}
      </Drawer>
    );
  }

  return (
    <section className="wraper-modal">
      <section className="py-3">
        <div id="dialog-container-recomendacion" />
        <Dialog
          align="center"
          open={true}
          fullWidth
          maxWidth="xs"
          onClose={(event, reason) => {
            if (reason !== "backdropClick") { }
          }}
          container={document.getElementById("dialog-container-recomendacion")}
        >
          {content}
        </Dialog>
      </section>
    </section>
  );
};

export default RecomendacionCambioClave;
