import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Drawer,
  Box,
  Typography,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DownloadIcon from '@mui/icons-material/Download';
import MessageSnack from "../../components/MessageSnack";
import ICON_INFO from "../../assets/icons/info_circle.svg";
import { Button } from "..";
import HomeContext from "../../context/home/homeContext";

const ActualizarDatosSscc = ({ onLogout, baseBiometria = true }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // En caso necesites capturar el celular cuando baseBiometria = false
  const [phoneNumber, setPhoneNumber] = useState("");
  const [apiResult, setApiResult] = useState(null);
  const { consultarOsiptel } = useContext(HomeContext);

  const handlePhoneChange = (e) => {
    // Remueve caracteres que no sean dígitos
    let value = e.target.value.replace(/\D/g, "");
    // Limita a 9 dígitos
    if (value.length > 9) value = value.slice(0, 9);
    // Inserta un espacio cada 3 dígitos
    value = value.replace(/(\d{3})(\d{0,3})(\d{0,3})/, (match, g1, g2, g3) => {
      let formatted = g1;
      if (g2) formatted += " " + g2;
      if (g3) formatted += " " + g3;
      return formatted;
    });
    setPhoneNumber(value);
  };

  // Valida que el número, sin espacios, tenga exactamente 9 dígitos
  const isPhoneValid = phoneNumber.replace(/\s/g, "").length === 9;

  /**
    * UI para el caso baseBiometria = true
  */
  const contentBaseBiometria = (
    <>
      <DialogTitle align="center">Tus datos necesitan actualizarse</DialogTitle>
      <DialogContent align="center">
        <Typography sx={{ fontSize: "14px" }}>
          Para seguir navegando en <strong>Mi Hábitat Digital</strong>, actualiza tus datos de forma rápida por WhatsApp.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
            border: "1px solid #C4C4C4",
            borderRadius: "16px",
            padding: "12px 16px",
            textAlign: "center",
            fontSize: "16px",
            color: "#000",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <WhatsAppIcon sx={{ color: "#CE1335", fontSize: 24, mr: 1 }} />
            <Box>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Chatbot Habi - Whatsapp
              </Typography>
              <Typography align="left" variant="body2">
                +51 981 211 004
              </Typography>
            </Box>
          </Box>
          <ChevronRightIcon sx={{ color: "#333" }} />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
          fullWidth
          sx={{
            display: "flex",
            margin: "0 auto",
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              window.open(
                "https://wa.me/51981211004?text=" +
                encodeURIComponent("Hola Habi, vengo desde la web para actualizar mis datos por biometría 👋"),
                "_blank"
              );
              onLogout();
            }}
          >
            Actualizar por WhatsApp
          </Button>
        </Box>
      </DialogContent>
    </>
  );

  /**
   * UI para el caso baseBiometria = false
   * (Aquí mostramos un input para el celular y cambiamos título y mensajes)
  */
  const contentSinBiometria = (
    <>
      <DialogTitle align="center">Confirma tu número de celular</DialogTitle>
      <DialogContent align="center">
        <Typography sx={{ fontSize: "14px" }}>
          Usaremos este número para validar tu identidad. Asegúrate de ingresar el que utilizas actualmente.
        </Typography>
        <Typography
          align="left"
          sx={{
            mt: 2,
            fontSize: "14px",
            fontWeight: "bold"
          }}>
          Celular
        </Typography>
        <Box sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="987 654 321"
            value={phoneNumber}
            onChange={handlePhoneChange}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            sx={{
              backgroundColor: "#F6F8F9",
              borderRadius: "16px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "16px",
                "& fieldset": {
                  border: "none",
                },
                "& input": {
                  textAlign: "left",
                  fontSize: "14px",
                  color: "#000",
                },
                "& input::placeholder": {
                  color: "#C4C4C4",
                },
              },
            }}
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
          fullWidth
          sx={{
            display: "flex",
            margin: "0 auto",
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              const phoneClean = phoneNumber.replace(/\s/g, "");
              consultarOsiptel(phoneClean)
                .then((response) => {
                  if (response === true) {
                    setApiResult(true);
                    window.open(
                      "https://wa.me/51981211004?text=" +
                      encodeURIComponent("Hola Habi, vengo desde la web para actualizar mis datos 👋"),
                      "_blank"
                    );
                    onLogout();
                  } else {
                    setApiResult(false);
                  }
                })
                .catch((error) => {
                  console.error("Error en la consulta a la API:", error);
                });
            }}
            disabled={!isPhoneValid}
          >
            Confirmar número
          </Button>
        </Box>
      </DialogContent>
    </>
  );

  /**
   * UI para el caso no pueda cambiar su datos el afiliado vía WSP
  */
  const contentDocumento = (
    <>
      <DialogTitle align="center">Necesitas actualizar tus datos</DialogTitle>
      <DialogContent align="left">
        {/* Texto de instrucciones */}
        <Typography sx={{ fontSize: "14px" }}>
          Para seguir navegando necesitas tener actualizado tus datos de contacto (correo y/o celular):
        </Typography>
        {/* Lista de pasos */}
        <Box
          sx={{
            textAlign: "left",
            mt: 2,
            fontSize: "14px",
            lineHeight: 1.5,
          }}
        >
          <Typography sx={{ fontSize: "14px", mb: 1 }}>
            1. Descarga el <strong>Documento de actualización de datos.</strong>
          </Typography>
          <Typography sx={{ fontSize: "14px", mb: 1 }}>
            2. Complétalo y fírmalo.
          </Typography>
          <Typography sx={{ fontSize: "14px", mb: 1 }}>
            3. Envíalo a{" "}
            <strong>
              miagenciavirtual@afphabitat.com.pe
            </strong>
            {" "}junto con:
          </Typography>
          <Typography component="ul" sx={{ listStyleType: 'disc', pl: 2 }}>
            <Typography component="li" sx={{ fontSize: "14px", ml: 2 }}>
              Foto legible de tu documento de identidad (frontal y reverso)
            </Typography>
          </Typography>
        </Box>
        {/* Mensaje de alerta */}
        <MessageSnack icon={ICON_INFO} alt="ICON_INFO" marginTop="1.2em">
          Recibirás una notificación cuando tus datos hayan sido actualizados con éxito.
        </MessageSnack>
        {/* Documento de actualización de datos (descarga) */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
            border: "1px solid #C4C4C4",
            borderRadius: "16px",
            padding: "12px 16px",
            textAlign: "center",
            fontSize: "16px",
            color: "#000",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Documento de actualización de datos
              </Typography>
            </Box>
          </Box>
          <DownloadIcon
            sx={{
              color: "#333536",
              cursor: "pointer"
            }}
            onClick={() => {
              window.open("/pdfs/Formato_de_Actualizacion_de_Datos.pdf", "_blank");
            }}
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        {/* Botón final */}
        <Box
          fullWidth
          sx={{
            display: "flex",
            margin: "0 auto",
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              onLogout();
            }}
          >
            Entendido
          </Button>
        </Box>
      </DialogContent>
    </>
  );

  // Determinamos qué contenido mostrar:
  // Si baseBiometria es true se muestra siempre contentBaseBiometria
  // Si es false, se evalúa el estado apiResult:
  //   - null: aún no se ha hecho la consulta (muestra el input)
  //   - true: se muestra contentBaseBiometria
  //   - false: se muestra contentDocumento
  let content;
  if (baseBiometria) {
    content = contentBaseBiometria;
  } else {
    // Aún no se llama a la API de OPSITEL
    if (apiResult === null) content = contentSinBiometria;
    // Se llamó a la API de OPSITEL y retornó TRUE
    else if (apiResult === true) content = contentBaseBiometria;
    // Se llamó a la API de OPSITEL y retornó FALSE (debe descargar el documento)
    else content = contentDocumento;
  }

  // Rendirizado según dispositivo
  if (isMobile) {
    return (
      <Drawer
        anchor="bottom"
        open={true}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") { }
        }}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            maxHeight: "90vh",
            overflow: "auto",
          },
        }}
      >
        {content}
      </Drawer>
    );
  }

  return (
    <section className="wraper-modal">
      <section className="py-3">
        <div id="dialog-container-sscc" />
        <Dialog
          align="center"
          open={true}
          fullWidth
          maxWidth="xs"
          onClose={(event, reason) => {
            if (reason !== "backdropClick") { }
          }}
          container={document.getElementById("dialog-container-sscc")}
        >
          {content}
        </Dialog>
      </section>
    </section>
  );
};

export default ActualizarDatosSscc;
