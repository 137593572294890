import React, { useEffect, useState, useContext } from "react";
import FormularioCambioClave from "../FormularioCambioClave";
import FormularioRatificaDatos from "./FormularioRatificaDatos";
import FormularioCorreo from "./FormularioCorreo";
import ModalMessage from "./messageModal/CustomModal";
import { useHistory } from "react-router-dom";
// SGR_21575 MIGRACION CLAVE WEB
import StepConfirmacion from "../StepConfirmacion";
import RecomendacionCambioClave from "..//RecomendacionCambioClave"
import ActualizarDatosSscc from "../ActualizarDatosSscc";
import AuthContext from "../../context/autenticacion/authContext";

const ModalUpdateClave = (props) => {
  const { type, info, datos, limpiarVariablesContext, baseBiometria } = props;
  const history = useHistory();

  // Cerrar sesión
  const authContext = useContext(AuthContext);
  const { cerrarSesion } = authContext;
  const closeSesion = () => {
    sessionStorage.removeItem("token");
    cerrarSesion(true, props.history.location);
    limpiarVariablesContext();
  };

  // Estados para manejar el wizard
  const [step, setStep] = useState(1);
  const [anyYes, setAnyYes] = useState(false);
  const [datosListos, setDatosListos] = useState(false);

  // Extraemos email y teléfono de los datos
  const email = datos?.r_datos?.r_correo?.trim() || "";
  const phone = datos?.r_datos?.r_celular?.trim() || "";

  // Función para formatear el número telefónico (agrupado de 3 en 3)
  const formatPhone = (number) => {
    const clean = number.replace(/\D/g, '');
    if (clean.length !== 9) return number;
    return clean.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
  };

  // Detectar si ya se tienen los datos (para evitar renderizar antes de tiempo)
  useEffect(() => {
    if (datos?.r_datos && (datos.r_datos.r_correo || datos.r_datos.r_celular)) {
      setDatosListos(true);
    }
  }, [datos]);

  // Aseguramos la ruta correcta
  useEffect(() => {
    if (type === "clave") {
      if (history.location.pathname !== "/mi-habitat-digital/afiliado/inicio") {
        history.push("/mi-habitat-digital/afiliado/inicio");
      }
    } else {
      if (info?.cambiar === "1") {
        if (history.location.pathname !== "/mi-habitat-digital/afiliado/inicio") {
          history.push("/mi-habitat-digital/afiliado/inicio");
        }
      }
    }
  }, [history.location.pathname]);

  // Si no existe email, saltamos desde el step 1:
  useEffect(() => {
    if (!datosListos) return;

    if (step === 1 && !email) {
      if (!phone) setStep(3);
      else setStep(2);
    }
  }, [step, email, phone, datosListos]);

  // Si en el step 2 no hay celular, vamos directo al step 3 (por si cambia la data)
  useEffect(() => {
    if (!datosListos) return;

    if (step === 2 && !phone) setStep(3);

  }, [step, phone, datosListos]);

  if (!datosListos) {
    return null;
  }

  let componente;

  if (type === "clave") {
    switch (step) {
      case 1:
        componente = (
          <StepConfirmacion
            titulo="Confirma tu correo electrónico"
            descripcion="Este es el correo electrónico que tenemos registrado.<br />¿Es correcto?"
            datoMostrado={email || "Obteniendo correo..."}
            onYes={() => {
              setAnyYes(true);
              if (!phone) {
                setStep(3);
              } else {
                setStep(2);
              }
            }}
            onNo={() => {
              if (!phone) {
                setStep(3);
              } else {
                setStep(2);
              }
            }}
          />
        );
        break;
      case 2:
        componente = (
          <StepConfirmacion
            titulo="Confirma tu número celular"
            descripcion="Este es el número de celular que tenemos registrado.<br />¿Es correcto?"
            datoMostrado={phone ? formatPhone(phone) : "Obteniendo número..."}
            onYes={() => {
              setAnyYes(true);
              setStep(3);
            }}
            onNo={() => {
              setStep(3);
            }}
          />
        );
        break;
      case 3:
        // Si el usuario confirmó al menos un dato, mostramos el formulario de actualización de clave.
        if (anyYes) {
          componente = (
            <FormularioCambioClave
              isModal={true}
              title="Actualización de clave web"
              description="Para mejorar tu experiencia hemos implementado nuevas medidas de seguridad para ti."
            />
          );
        } else {
          // Si no confirmó ningún dato, entramos a lógica de componente de SSCC
          componente = (
            <ActualizarDatosSscc
              onLogout={() => {
                closeSesion();
              }}
              baseBiometria={baseBiometria}
            />
          );
        }
        break;
      default:
        componente = null;
    }
  }
  // Recomendación de 6 meses de último cambio de clave
  else if (type === "recomendacion") {
    componente = (
      <RecomendacionCambioClave />
    );
  }
  else {
    // Lógica para otros tipos (no "clave")
    if (info.cambiar === "1" && datos !== undefined) {
      if (
        datos?.r_datos?.r_celular?.replace(/\s/g, "") ||
        datos?.r_datos?.r_correo?.replace(/\s/g, "")
      ) {
        componente = <FormularioCorreo />;
      } else {
        componente = <ModalMessage showModal={true} history={props.history} />;
      }
    } else {
      componente = <FormularioRatificaDatos />;
    }
  }
  return componente;
};

export default ModalUpdateClave;
